import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Section, Link, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Cityscape Drive Rentals
			</title>
			<meta name={"description"} content={"Our selection of cars matches the rhythm of city life, offering a perfect blend of mobility and style. Each rental here is an opportunity to immerse yourself in the heart of the city with ease and elegance.\n"} />
			<meta property={"og:title"} content={"Cityscape Drive Rentals"} />
			<meta property={"og:description"} content={"Our selection of cars matches the rhythm of city life, offering a perfect blend of mobility and style. Each rental here is an opportunity to immerse yourself in the heart of the city with ease and elegance.\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-4.jpg?v=2024-02-14T11:57:57.504Z) 50% 70%/cover"
			padding="64px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						About Us
					</Text>
					<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
						Where Every Turn Unfolds a New Story
					</Text>
				</StackItem>
			</Stack>
			<Box text-align="center" margin="96px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					More
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Our Philosophy
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Cityscape Drive Rentals is not just about providing transportation; it’s the key to unlocking the vibrant pulse of the city. Our fleet, carefully curated for the urban environment, will be your companion in weaving the tapestry of city streets, revealing hidden gems and stories that lie within.
				</Text>
				<Link
					href="#"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Services
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-2.jpg?v=2024-02-14T11:57:57.508Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-2.jpg?v=2024-02-14T11%3A57%3A57.508Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-2.jpg?v=2024-02-14T11%3A57%3A57.508Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-2.jpg?v=2024-02-14T11%3A57%3A57.508Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-2.jpg?v=2024-02-14T11%3A57%3A57.508Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-2.jpg?v=2024-02-14T11%3A57%3A57.508Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-2.jpg?v=2024-02-14T11%3A57%3A57.508Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-2.jpg?v=2024-02-14T11%3A57%3A57.508Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-3.jpg?v=2024-02-14T11:57:57.490Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					object-position="10%"
					srcSet="https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-3.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-3.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-3.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-3.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-3.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-3.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/2-3.jpg?v=2024-02-14T11%3A57%3A57.490Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					The Cityscape Drive Path
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Founded with the goal of making city exploration accessible and enjoyable for everyone, Cityscape Drive Rentals has become a beacon for urban adventure enthusiasts. Our foundation is built on the belief that the best way to get to know a city is not just to drive through it but to become part of its continuous narrative. Through our cars, we provide the connection between you and the ever-evolving story of the city.
				</Text>
				<Link
					href="#"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Services
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="\n\n0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Why Choose Cityscape Drive?
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					- Commitment to Convenience: We prioritize your experience, ensuring a smooth, transparent, and tailored rental process.
					<br />
					- Quality and Variety: Our fleet includes everything from eco-friendly compact cars to luxurious sedans, all maintained to the highest standards for your safety and comfort.
					<br />
					- Insider Knowledge: With a deep understanding of urban dynamics, we provide you not just a car but the insights to unlock the city’s best-kept secrets.
					<br />
					- Sustainability Focus: In our operations and car selection, we aim to minimize environmental impact and promote sustainable urban living.
				</Text>
				<Link
					href="#"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Services
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-3.jpg?v=2024-02-14T11:57:57.505Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-3.jpg?v=2024-02-14T11%3A57%3A57.505Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-3.jpg?v=2024-02-14T11%3A57%3A57.505Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-3.jpg?v=2024-02-14T11%3A57%3A57.505Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-3.jpg?v=2024-02-14T11%3A57%3A57.505Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-3.jpg?v=2024-02-14T11%3A57%3A57.505Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-3.jpg?v=2024-02-14T11%3A57%3A57.505Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-3.jpg?v=2024-02-14T11%3A57%3A57.505Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});